import axios from 'axios';
import endpoints from '@/js/urls';

const supsearchState = {
  rankers: [],
  isFetchingRankers: false,
  dataSources: [],
  isFetchingDataSources: false,
  isSearchingArticles: false,
};
const getters = {

};
const mutations = {
  setRankers(state, payload) {
    state.rankers = payload;
  },
  setIsFetchingRankers(state, value) {
    state.isFetchingRankers = value;
  },
  setDataSources(state, payload) {
    state.dataSources = payload;
  },
  setIsFetchingDataSources(state, value) {
    state.isFetchingDataSources = value;
  },
  setIsSearchingArticles(state, value) {
    state.isSearchingArticles = value;
  },
};
const actions = {
  async fetchRankers({ rootState, commit, dispatch }) {
    try {
      commit('setIsFetchingRankers', true);
      commit('setRankers', []);
      const resp = await axios.get(endpoints.supSearchRanker,
        {
          params: {},
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      commit('setRankers', resp.data.results);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch rankers',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setIsFetchingRankers', false);
    }
  },
  async fetchDataSources({ rootState, commit, dispatch }) {
    try {
      commit('setIsFetchingDataSources', true);
      commit('setDataSources', []);
      const resp = await axios.get(endpoints.supSearchDataSource,
        {
          params: {},
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      commit('setDataSources', resp.data.results);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch rankers',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setIsFetchingDataSources', false);
    }
  },
  async searchArticles({ rootState, commit, dispatch }, params) {
    try {
      commit('setIsSearchingArticles', true);
      const resp = await axios.get(endpoints.supSearchArticle,
        {
          params,
          headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        });
      if (!resp.data.results.length) {
        dispatch('sidebar/showWarning', {
          title: 'No articles found',
          text: 'No matching articles found. Try refining your search',
          variant: 'info',
        }, { root: true });
        return [];
      }
      return resp.data.results;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to search articles',
        text: error.message,
        variant: 'danger',
      }, { root: true });
      return [];
    } finally {
      commit('setIsSearchingArticles', false);
    }
  },
};
export default {
  namespaced: true,
  state: supsearchState,
  getters,
  mutations,
  actions,
};
