<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="danger"
    :allow-expand="allowExpand"
    @shown="focusInput"
  >
    <template #icon>
      <font-awesome-icon
        icon="directions"
        :class="flipClass"
      />
    </template>
    <template #header>
      {{ name }} {{ headerName }}
    </template>
    <template v-if="loopType === 'ifLoop'">
      <b-card v-if="['if', 'else if'].includes(name)">
        <b-row>
          <b-col
            class="px-1 text-right font-weight-bold mt-2 w-100"
          >
            Condition:
          </b-col>
          <b-col cols="10">
            <botscript-validation
              ref="inputComponent"
              :value="statement"
              @onChange="v=>statement = v"
            />
          </b-col>
        </b-row>
      </b-card>
    </template>
    <template v-else-if="loopType === 'forLoop'">
      <b-card v-if="name === 'for'">
        <b-row
          class="mt-1"
        >
          <b-col class="text-right font-weight-bold mt-2">
            Iterable:
          </b-col>
          <b-col cols="10">
            <botscript-validation
              ref="inputComponent"
              :value="iterable"
              :validations="['empty', 'typecheck-iterable']"
              @onChange="v=>iterable = v"
            />
          </b-col>
        </b-row>
        <b-row
          class="mt-1"
        >
          <b-col class="text-right font-weight-bold mt-2">
            Item:
          </b-col>
          <b-col cols="10">
            <VariableName
              v-model="item"
              placeholder="Provide a name to be used for the loop variable"
            />
          </b-col>
        </b-row>
      </b-card>
    </template>
  </base-activity>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import VariableName from '@/components/VariableName.vue';
import { truncateString } from '@/js/utils';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

export default {
  name: 'ControlFlowActivity',
  components: {
    BaseActivity,
    BotscriptValidation,
    VariableName,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      name: 'activityName',
      loopType: 'activityControlFlowLoopType',
      statementStore: 'activityControlFlowStatement',
      iterableStore: 'activityControlFlowIterable',
      itemStore: 'activityControlFlowItem',
    }), 'nodeId', 'activityId'),
    headerName() {
      if (['if', 'else if'].includes(this.name)) {
        return truncateString(this.statement, 50);
      }
      if (this.name === 'for') {
        return `${truncateString(this.item, 30)} in ${truncateString(this.iterable, 30)}`;
      }
      return '';
    },
    flipClass() {
      // Flip icon if the activity is end-if
      return this.name === 'end if' ? 'fa-flip-vertical' : '';
    },
    allowExpand() {
      if (this.loopType === 'ifLoop' && ['else', 'end if'].includes(this.name)) {
        return false;
      }
      if (this.loopType === 'forLoop' && this.name === 'end for') {
        return false;
      }
      return true;
    },
    statement: {
      get() {
        return this.statementStore;
      },
      set(val) {
        this.setStatement({ statement: val });
      },
    },
    iterable: {
      get() {
        return this.iterableStore;
      },
      set(val) {
        this.setIterable({ iterable: val });
      },
    },
    item: {
      get() {
        return this.itemStore;
      },
      set(val) {
        this.setItem({ item: val });
      },
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setStatement: 'setActivityControlFlowStatement',
      setIterable: 'setActivityControlFlowIterable',
      setItem: 'setActivityControlFlowItem',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    focusInput() {
      if (this.$refs.inputComponent?.focusInput) {
        this.$refs.inputComponent.focusInput();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
  },
};

</script>

<style scoped>

</style>
