<template>
  <b-card
    no-body
    class="mb-1 border"
    :style="`margin-left: ${indent * 1.5}em;`"
  >
    <b-card-header
      header-tag="header"
      class="p-0"
      role="tab"
    >
      <b-button
        block
        href="#"
        :variant="bsVariant"
        class="text-left"
        @mouseenter="mouseHover = true"
        @mouseleave="mouseHover = false"
        @click="toggleShow"
      >
        <b-row no-gutters align-v="center">
          <b-col cols="auto">
            <slot name="icon" />
          </b-col>
          <b-col class="px-2" style="word-wrap:normal;">
            <slot name="header" />
          </b-col>
          <b-col cols="auto">
            <a
              v-b-tooltip.hover
              href="#"
              class="float-right"
              title="Delete this activity"
              @click.stop.prevent="deleteActivityPrompt"
            >
              <font-awesome-icon
                icon="trash-alt"
                color="white"
              />
            </a>
            <a
              v-b-tooltip.hover
              href="#"
              class="float-right mr-2"
              title="Copy activity"
              @click.stop.prevent="copyActivityId"
            >
              <font-awesome-icon
                icon="copy"
                color="white"
              />
            </a>
            <a
              v-b-tooltip.hover
              href="#"
              class="float-right mr-2"
              title="Duplicate activity"
              @click.stop.prevent="cloneActivity"
            >
              <font-awesome-icon
                icon="clone"
                color="white"
              />
            </a>
            <a
              v-if="showContents && advancedIcon"
              v-b-tooltip.hover
              href="#"
              class="float-right mr-2 h4 mb-0"
              title="Advanced options"
              @click.stop.prevent="toggleExtraContents()"
            >
              <font-awesome-icon
                :icon="advancedIcon"
                color="white"
              />
            </a>
          </b-col>
        </b-row>
      </b-button>
    </b-card-header>
    <b-collapse
      :id="accordion + '-' + nodeId + '-' + activityId"
      v-model="showContents"
      :accordion="accordion"
      role="tabpanel"
      @shown="$emit('shown')"
      @hidden="$emit('hidden')"
    >
      <b-card-body v-if="showContents">
        <b-card-text>
          <slot />
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import copy from 'clipboard-copy';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { addThisArgs } from '@/js/storeHelpers';
import { RESPONSE } from '@/js/activity';

export default {
  name: 'BaseActivity',
  props: {
    accordion: {
      type: String,
      default: () => 'activity-accordion',
    },
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    bsVariant: {
      type: String,
      required: false,
      default: 'info',
    },
    indent: {
      type: Number,
      required: true,
    },
    allowExpand: {
      type: Boolean,
      required: false,
      default: true,
    },
    advancedIcon: {
      default: '',
      type: String,
    },
    // showExtraContents is check by listener instead of property
  },
  data() {
    return {
      showContents: false,
      mouseHover: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['nodeById']),
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'deleteActivity',
      'cloneActivity',
    ]), { nodeId: 'nodeId', activityId: 'activityId' }),
    ...mapActions('sidebar', ['showWarning']),
    async deleteActivityPrompt() {
      if (this.nodeById(this.nodeId).activities[this.activityId].type !== RESPONSE) {
        if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this activity?', {
          title: 'Delete activity',
          okTitle: 'Delete activity',
          okVariant: 'danger',
          autoFocusButton: 'ok',
        })) {
          this.deleteActivity();
        }
      } else {
        this.deleteActivity();
      }
    },
    copyActivityId() {
      copy(`${this.nodeId}/${this.activityId}`);
      this.showWarning({
        title: 'Activity ID copied',
        text: 'Activity ID copied to pasteboard.',
        variant: 'primary',
      });
    },
    focusActivity() {
      if (this.allowExpand) {
        this.showContents = true;
      }
    },
    toggleExtraContents() {
      this.$emit('toggleExtraContents');
    },
    toggleShow() {
      if (this.allowExpand) {
        this.showContents = !this.showContents;
        if (this.showContents) {
          this.$emit('show-content');
        }
      }
    },
  },
};
</script>
<style scoped>
::v-deep .card-header{
  border-bottom: none;
}
</style>
