<template>
  <div>
    <b-dropdown
      ref="dropdown"
      dropleft
      no-caret
      variant="primary"
      menu-class="bg-white"
      @show="setValuesOnShow"
    >
      <template #button-content>
        <font-awesome-icon icon="cog" />
        <span class="sr-only">Configure results</span>
      </template>
      <b-dropdown-form
        form-class="px-2 mb-0"
        @submit.stop.prevent="recompute"
      >
        <b-form-group
          label="Number of topics"
          class="mb-0"
        >
          <b-form-input
            v-model.number="topicCount"
            v-b-tooltip.noninteractive.viewport="topicCount === null ? 'Select number of topics' : topicCount"
            min="1"
            step="1"
            :max="maxTopics"
            type="range"
            @click.stop
          />
        </b-form-group>
        <b-form-group
          label="Specificity"
          title="A high specificity favors selection of top keywords that are unique to the topic."
          class="mb-0"
        >
          <b-form-input
            v-model="specificityValue"
            v-b-tooltip.noninteractive.viewport="specificityValue === null ? 'Select specificity' : specificityValue"
            min="0"
            step="0.01"
            max="1"
            type="range"
            @click.stop
          />
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          size="sm"
          @click.stop
        >
          Update
        </b-button>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>

export default {
  name: 'ConfigurationDropdown',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    defaultNumberOfTopics: {
      type: [Number, String],
      default: 0,
    },
    defaultSpecifityValue: {
      type: Number,
      default: 0.65,
    },
  },
  data() {
    return {
      topicCount: 0,
      specificityValue: null,
    };
  },
  computed: {
    maxTopics() {
      return Math.min(50, Math.max(1, this.data?.topics?.length));
    },
  },
  methods: {
    setValuesOnShow() {
      if ([0, null].includes(this.topicCount)) {
        this.topicCount = Math.min(
          this.defaultNumberOfTopics,
          this.maxTopics,
        );
      }
      if (this.specificityValue === null) {
        this.specificityValue = this.defaultSpecifityValue;
      }
    },
    recompute() {
      if ((![0, null].includes(this.topicCount) && this.topicCount !== this.defaultNumberOfTopics)
      || this.specificityValue !== this.defaultSpecifityValue) {
        this.$emit('updateTopicConfig', { topicCount: Math.floor(this.topicCount), specificityValue: this.specificityValue });
      }
      this.$refs.dropdown.hide();
    },
  },
};
</script>
