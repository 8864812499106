export function deepEqualsJson(a, b) {
  if (typeof a !== typeof b) {
    return false;
  }
  if (a instanceof Array) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (!deepEqualsJson(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }
  if (a instanceof Object) {
    if (b === null) { // Corner case as javascript is stupid and defines typeof null === "object"
      return false;
    }
    if (!deepEqualsJson(Object.keys(a).sort(), Object.keys(b).sort())) {
      return false;
    }
    for (const key of Object.keys(a)) {
      if (!deepEqualsJson(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  return a === b;
}

export function deepCopyJson(val) {
  if (val instanceof Array) {
    const result = [];
    for (const elem of val) {
      result.push(deepCopyJson(elem));
    }
    return result;
  }
  if (val instanceof Object) {
    const result = {};
    for (const key of Object.keys(val)) {
      result[key] = deepCopyJson(val[key]);
    }
    return result;
  }
  return val;
}

/**
 * Merge 2 objects together
 * @param {object} originalObject - original object
 * @param {object} objectToMerge - object to merge or overwrite into original object
 * @example
 * mergeObject({ a: 1 }, { a: 2, b: 3 })
 * // returns { a: 2, b: 3 }
 * @returns {object} merged object
 */
export function mergeObjects(originalObject, objectToMerge) {
  if (!originalObject || typeof originalObject !== 'object') return objectToMerge;
  const resultObj = structuredClone(originalObject);
  Object.entries(objectToMerge).forEach(([key, value]) => {
    let result;
    if (typeof value === 'object' && !Array.isArray(value)) {
      result = mergeObjects(resultObj[key], value);
    } else {
      result = value;
    }
    resultObj[key] = result;
  });
  return resultObj;
}

// SNAKE-CAMEL-CONVERSION
export function strToSnake(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
export function objToSnake(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => {
    // for some reason js considers null and [] an object, so additional check is required
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      return [strToSnake(k), objToSnake(v)];
    }
    return [strToSnake(k), v];
  }));
}
export function strToCamel(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase().replace('-', '').replace('_', ''),
  );
}
export function objToCamel(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => {
    // for some reason js considers null and [] an object, so additional check is required
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      return [strToCamel(k), objToCamel(v)];
    }
    return [strToCamel(k), v];
  }));
}
export function camelStrToText(str) {
  return str
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, (e) => e.toUpperCase());
}
export function snakeCaseToText(str){
  return str ? str.split('_').map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(' ') : null;
}
export const anonymizationTypes = Object.freeze([
  { value: 'swml', text: 'Built-in recognizer' },
  { value: 'regex', text: 'Regular Expression' },
]);

export const specialNersDict = Object.freeze({
  ListNER: 'List NER',
  CPRNumberRecognizer: 'CPR Number Recognizer',
  IPRecognizer: 'IP Recognizer',
});

// use for charts and other features that require many colors
export const matchingColors = [
  'rgb(227, 220, 141)', // #e3dc8d lightyellow
  'rgb(160, 184, 119)', // #a0b877 lightgreen
  'rgb(83, 173, 122)', // #53ad7a lightgreen2
  'rgb(53, 166, 139)', // #35a68b teal
  'rgb(80, 135, 92)', // #50875c darkgreen
  'rgb(93, 154, 158)', // #5d9a9e lightblue
  'rgb(51, 126, 176)', // #337eb0 blue
  'rgb(37, 84, 128)', // #255480 darkblue3
  'rgb(52, 73, 94)', // #34495e darkblue
  'rgb(86, 108, 130)', // #566c82 darkblue2
  'rgb(115, 129, 140)', // #73818c grey
  'rgb(140, 163, 163)', // #8ca3a3 lightgrey
  'rgb(118, 125, 173)', // #767dad lightpurple
  'rgb(102, 110, 145)', // #666e91 darkpurple
  'rgb(207, 185, 147)', // #cfb993 beige
  'rgb(212, 142, 95)', // #d48e5f lightorange
  'rgb(214, 133, 62)', // #d6853e orange
  'rgb(219, 129, 152)', // #db8198 lightpink
  'rgb(209, 142, 147)', // #d18e93 lightred
  'rgb(122, 94, 101)', // #d18e93 mauve
];
export function shuffledMatchingColors() {
  const shuffledArray = matchingColors.slice();
  // Fisher-Yates shuffle algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}


export function guidGenerator() {
  /**
   * @return {string}
   */
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}
