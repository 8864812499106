<template>
  <b-modal
    :id="modalId"
    size="xl"
    :title="isEdit ? 'Update source' : 'Add source'"
    :cancel-disabled="isEditingOrCreating && !failedToCreateDatasource"
    :ok-disabled="$v.modalSource.$invalid || isEditingOrCreating"
    @ok="okClicked"
    @show="setupState"
    @hidden="resetSourceModal"
  >
    <template #modal-ok>
      <b-spinner
        v-if="isEditingOrCreating && !failedToCreateDatasource"
        small
      />
      {{ okButtonTitle }}
    </template>
    <b-form>
      <b-form-group
        label="Name"
        label-for="inputSourceName"
      >
        <b-form-input
          id="inputSourceName"
          v-model="modalSource.name"
          type="text"
          class="form-control"
          placeholder="Name"
          :state="falseOrNull($v.modalSource.name.$invalid)"
          aria-describedby="sourceNameFeedback"
        />
        <b-form-invalid-feedback id="sourceNameFeedback">
          <div v-if="!$v.modalSource.name.required">
            Your source must have a name.
          </div>
          <div v-if="!$v.modalSource.name.uniqueName">
            Your source name must be unique.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Description"
        label-for="inputSourceDescription"
      >
        <b-form-input
          id="inputSourceDescription"
          v-model="modalSource.description"
          type="text"
          class="form-control"
          placeholder="Description"
          :state="falseOrNull($v.modalSource.description.$invalid)"
        />
      </b-form-group>
      <b-form-group
        label="Type"
        label-for="inputSourceType"
      >
        <b-form-select
          id="inputSourceType"
          v-model="modalSource.type"
          :options="types"
          :disabled="isEdit"
          :state="falseOrNull($v.modalSource.type.$invalid)"
          aria-describedby="sourceTypeFeedback"
        />
        <b-form-invalid-feedback id="sourceTypeFeedback">
          <div v-if="!$v.modalSource.type.required">
            You must specify a source type.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <template v-if="modalSource.type === 'node'">
        <bot-node-selector
          :value="modalSource.node"
          :disabled="isEdit"
          :show-add-button="false"
          :show-label-selector="true"
          :loading="isEditingOrCreating"
          @input="v=>updateNode(v)"
        />
        <b-form-group
          v-if="modalSource.node && modalSource.node.botId"
          label="Additional filtering"
          aria-describedby="dateTimePickerFeedback"
        >
          <date-time-lang-picker
            date
            time
            lang
            shortcuts
            :disabled="isEdit"
            :getter="modalSource.dateTimeLangFilter"
            :setter="updateDateTimeLangFilter"
          />
          <b-form-invalid-feedback
            id="dateTimePickerFeedback"
            :state="falseOrNull($v.modalSource.dateTimeLangFilter.$invalid)"
          >
            You must specify a date and time range.
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <template
        v-else-if="modalSource.type === 'file'"
      >
        <b-form-group
          v-if="!isEdit"
          label="File"
          label-for="inputSourceFile"
        >
          <b-form-file
            id="inputSourceFile"
            v-model="modalSource.file"
            :state="falseOrNull($v.modalSource.file.$invalid)"
            placeholder="Choose a file or drop it here"
            drop-placeholder="Drop file here..."
            aria-describedby="sourceFileFeedback"
          />
          <b-form-invalid-feedback id="sourceFileFeedback">
            <div v-if="!$v.modalSource.file.required">
              You must choose a file or drop it in the box above.
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="!isEdit">
          <label>Data anonymizers</label>
          <tooltipped-text
            class="ml-1"
            value="Data anonymizers being applied to the data before storing it in the
          database"
          />
          <b-form-checkbox
            v-model="anonymizerAllSelected"
            :indeterminate="anonymizerIndeterminate"
            :disabled="isEdit"
            @change="toggleAllAnonymizer"
          >
            {{ anonymizerAllSelected ? 'Un-select all' : 'Select all' }}
          </b-form-checkbox>
          <b-form-checkbox-group
            id="inputSourceAnonymizers"
            v-model="modalSource.anonymizers"
            :options="anonymizerOptions"
            :disabled="isEdit"
            switches
            stacked
            class="ml-4"
          />
        </b-form-group>
      </template>
      <template
        v-if="showProgressBar"
      >
        Uploading file:
        <b-progress
          v-if="isEditingOrCreating"
          max="100"
          striped
          :animated="dataSourceUploadProgress < 100"
        >
          <b-progress-bar
            :value="dataSourceUploadProgress"
            :label="`${dataSourceUploadProgress.toFixed(2)}%`"
          />
        </b-progress>
      </template>
      <div
        v-if="failedToCreateDatasource"
        style="float:right; text-align:right"
      >
        <span class="text-danger">
          Failed to create datasource <br> {{ createDatasourceErrorMessage }}
        </span>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import moment from 'moment';
import { applyThisArgs } from '@/js/storeHelpers';
import { DATASOURCE_CREATE_FAILED } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';
import DateTimeLangPicker from '@/components/DateTimeLangPicker.vue';
import { filterDateBuilder, getTimeFloatFromMoment } from '@/js/utils';
import BotNodeSelector from '@/components/BotNodeSelector.vue';

// Defined here to avoid unnamed function in watcher
function updateSelectAll(newValue) {
  if (newValue.length === 0) {
    this.anonymizerIndeterminate = false;
    this.anonymizerAllSelected = false;
  } else if (newValue.length === this.anonymizerOptions.length) {
    this.anonymizerIndeterminate = false;
    this.anonymizerAllSelected = true;
  } else {
    this.anonymizerIndeterminate = true;
    this.anonymizerAllSelected = false;
  }
}

export default {
  name: 'DataExplorationAddDataSourceModal',
  components: {
    TooltippedText,
    DateTimeLangPicker,
    BotNodeSelector,
  },
  mixins: [validationMixin],
  props: {
    modalId: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    sourceId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      modalSource: {
        name: '',
        description: '',
        type: null,
        file: null,
        node: {},
        anonymizers: [],
        dateTimeLangFilter: {
          selectedLanguage: 'any',
          startDate: null,
          endDate: null,
          startTime: 0,
          endTime: 24,
        },
      },
      anonymizerOptions: [
        { text: 'Website domains', value: 'DomainRecognizer' },
        { text: 'Email address', value: 'EmailRecognizer' },
        { text: 'IP-address', value: 'IPRecognizer' },
        { text: 'Phone number', value: 'PhoneNumberRecognizer' },
        { text: 'Address', value: 'AddressRecognizer' },
        { text: 'Girokort', value: 'GirokortRecognizer' },
        { text: 'CPR number', value: 'CPRNumberRecognizer' },
        { text: 'Names', value: 'FullNameRecognizer' },
      ],
      anonymizerAllSelected: false,
      anonymizerIndeterminate: false,
      editId: null,
      types: [
        { value: null, text: 'Please select a type' },
        { value: 'file', text: 'File' },
        { value: 'node', text: 'Bot node' },
      ],
      createDatasourceErrorMessage: null,
    };
  },
  computed: {
    ...mapState('dataExploration/dataSources',
      [
        'dataSourceEditingState',
        'dataSourceCreatingState',
        'dataSourceUploadProgress',
      ]),
    ...mapGetters('dataExploration/dataSources', ['isDeleting']),
    ...applyThisArgs(mapGetters('dataExploration', {
      dataset: 'getDatasetById',
    }), 'datasetId'),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    tableFields() {
      return [
        'name',
        'description',
        'type',
        'data_count',
        'label_count',
        {
          key: 'actions',
          label: '',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ];
    },
    isEditingOrCreating() {
      return this.dataSourceEditingState !== null
        || this.dataSourceCreatingState !== null;
    },
    failedToCreateDatasource() {
      return DATASOURCE_CREATE_FAILED === this.dataSourceCreatingState;
    },
    okButtonTitle() {
      if (this.isEdit) {
        return this.isEditingOrCreating ? 'Updating' : 'Update';
      }
      if (this.failedToCreateDatasource) {
        return 'Upload failed';
      }
      return this.isEditingOrCreating ? 'Adding source' : 'Add source';
    },
    showProgressBar() {
      return this.modalSource.type === 'file'
        && this.modalSource.file !== null
        && this.isEditingOrCreating
        && this.dataSourceUploadProgress !== 100
        && !this.failedToCreateDatasource;
    },
    sources() {
      if (this.dataset) {
        return Object.assign({}, ...this.dataset.data_sources.map((x) => ({ [x.id]: x })));
      }
      return [];
    },
    getModalSourceREST() {
      const setNode = this.modalSource.node !== {};
      const source = {
        dataset_id: this.datasetId,
        name: this.modalSource.name,
        description: this.modalSource.description,
        type: this.modalSource.type,
        file: this.modalSource.file,
        node_id: setNode ? this.modalSource.node.parentNodeId : null,
        bot_id: setNode ? this.modalSource.node.botId : null,
        node_child: setNode ? this.modalSource.node.childNodeId : null,
        actual_label: setNode ? this.modalSource.node.actualLabelName : null,
        language: this.modalSource.dateTimeLangFilter.selectedLanguage,
        date_from: this.modalSource.dateTimeLangFilter.startDate
          ? filterDateBuilder(
            this.modalSource.dateTimeLangFilter.startDate,
            this.modalSource.dateTimeLangFilter.startTime,
          ).toISOString() : null,
        date_to: this.modalSource.dateTimeLangFilter.endDate
          ? filterDateBuilder(
            this.modalSource.dateTimeLangFilter.endDate,
            this.modalSource.dateTimeLangFilter.endTime,
          ).toISOString() : null,
        anonymizers: this.modalSource.anonymizers,
      };
      if (this.editId) {
        source.id = this.editId;
      }
      return source;
    },
    modalSourceNode() {
      return this.modalSource.node;
    },
    nodeRequired() {
      return !this.isEdit && this.modalSource.type === 'node';
    },
    fileRequired() {
      return !this.isEdit && this.modalSource.type === 'file';
    },
  },
  watch: {
    'modalSource.anonymizers': updateSelectAll,
  },
  methods: {
    ...mapActions('dataExploration/dataSources', [
      'createDataSource',
      'editDataSource',
    ]),
    ...mapMutations('dataExploration/dataSources', [
      'setDataSourceCreatingState',
    ]),
    ...mapMutations('chatlogs', ['setSelectedBot', 'setAvailableLanguages']),
    updateDateTimeLangFilter({ key, newValue }) {
      this.modalSource.dateTimeLangFilter[key] = newValue;
    },
    toggleAllAnonymizer(checked) {
      this.modalSource.anonymizers = checked ? this.anonymizerOptions.map((x) => x.value) : [];
    },
    falseOrNull(val) {
      return val ? false : null;
    },
    getDataSourceByName(name) {
      return Object.values(this.sources).find((x) => x.name === name);
    },
    updateNode(payload) {
      if (payload.key === 'botId') {
        this.setAvailableLanguages(null);
        this.setSelectedBot(payload.value);
      }
      this.$set(this.modalSource.node, payload.key, payload.value);
    },
    prepareEditSourceModal() {
      const source = this.sources[this.sourceId];
      const isNode = source.type === 'node';
      const isFile = source.type === 'file';
      this.editId = this.sourceId;
      this.modalSource.name = source.name;
      this.modalSource.description = source.description;
      this.modalSource.type = source.type;
      this.modalSource.file = null;
      this.modalSource.node = {};
      if (isNode && source.node_bot) {
        this.setAvailableLanguages(null);
        this.setSelectedBot(source.node_bot);
        this.modalSource.node = {
          botId: source.node_bot,
          parentNodeId: source.node_id,
          childNodeId: source.node_child,
          actualLabelName: source.actual_label,
        };
      }
      const startDateTime = source.date_from ? moment(source.date_from) : null;
      const endDateTime = source.date_to ? moment(source.date_to) : null;
      this.modalSource.dateTimeLangFilter.selectedLanguage = source.language || 'any';

      this.modalSource.dateTimeLangFilter.startDate = (isNode && startDateTime)
        ? new Date(startDateTime.format('YYYY-MM-DD')) : null;

      this.modalSource.dateTimeLangFilter.endDate = (isNode && endDateTime)
        ? new Date(endDateTime.format('YYYY-MM-DD')) : null;

      this.modalSource.dateTimeLangFilter.startTime = (isNode && startDateTime)
        ? getTimeFloatFromMoment(startDateTime) : 0;

      this.modalSource.dateTimeLangFilter.endTime = (isNode && endDateTime)
        ? getTimeFloatFromMoment(endDateTime) : 24;

      this.modalSource.anonymizers = isFile && source.anonymizers !== null
        ? source.anonymizers : [];
      this.originalName = this.modalSource.name;
    },
    prepareAddSourceModal() {
      this.modalSource.name = '';
      this.modalSource.description = '';
    },
    okClicked(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.$v.modalSource.$invalid) {
        return false;
      }
      this.handleSubmit();
      return false;
    },
    async handleSubmit() {
      if (this.isEdit) {
        // Edit source using vuex
        await this.editDataSource({ dataSource: this.getModalSourceREST });
      } else {
        // Create dataset using vuex
        try {
          await this.createDataSource({ dataSource: this.getModalSourceREST });
        } catch (error) {
          // Don't hide modal: We wish to show to the user something wen't wrong!
          if (error.response?.data?.error) {
            this.createDatasourceErrorMessage = error.response.data.error;
          } else {
            this.createDatasourceErrorMessage = error;
          }
          return;
        }
      }
      this.$bvModal.hide(this.modalId);
    },
    resetSourceModal() {
      this.modalSource = {
        name: '',
        description: '',
        type: null,
        file: null,
        node: {},
        anonymizers: [],
        dateTimeLangFilter: {
          selectedLanguage: 'any',
          startDate: null,
          endDate: null,
          startTime: 0,
          endTime: 24,
        },
      };
      this.editId = null;
      this.createDatasourceErrorMessage = null;
    },
    setupState() {
      this.resetSourceModal();
      this.setDataSourceCreatingState({ createState: null });
      if (this.isEdit) {
        this.prepareEditSourceModal();
      } else {
        this.prepareAddSourceModal();
      }
    },
  },
  validations() {
    return {
      modalSource: {
        name: {
          required,
          uniqueName(value) {
            if (this.isEdit && value === this.originalName) {
              return true;
            }
            const foundDataset = this.getDataSourceByName(value);
            return foundDataset === undefined;
          },
        },
        description: {},
        type: {
          required,
        },
        file: {
          required: requiredIf(() => this.fileRequired),
        },
        node: {
          botId: {
            required: requiredIf(() => this.nodeRequired),
          },
        },
        dateTimeLangFilter: {
          // Note, that language is allowed to be null because it means "any language"
          startDate: {
            required: requiredIf(() => this.nodeRequired),
          },
          endDate: {
            required: requiredIf(() => this.nodeRequired),
          },
          startTime: {
            required: requiredIf(() => this.nodeRequired),
          },
          endTime: {
            required: requiredIf(() => this.nodeRequired),
          },
        },
      },
    };
  },
};
</script>
